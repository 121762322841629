.am_main {
  height: 100vh;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}

.am_intro {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 18px;
}

.am_context {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  width: 50%;

}

p {
  margin-bottom: 1.5em;
}
