.home {
  height: 100vh;
}

.main__projects {
  height: calc(100vh - 250px);
  display: grid;
  grid-template-columns: repeat(2, 500px);
  justify-content: center;
  grid-gap: 2rem;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 3rem;
  line-height: 1.2;
  padding: 50px;
}

.card-i_item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
  text-decoration: none;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #e5e5e5;
}

.card-i_item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.card-i_item.dark-mode {
  color: #c6c5c5;
  background-color: #7b7b7b; /* Dark mode background color */
  box-shadow: none; /* Remove box shadow in dark mode */
}

.card-i-item_title {
  display: grid;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  font-size: 30px;
  color: #000000;
}

.card-i-item_title.dark-mode {
  color: #c6c5c5;
}

.card-i-item_title:hover {
  opacity: 0.8;
  color: #5b62ae;
  transition: all 0.3s ease-in;
}
