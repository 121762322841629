body {
  background-color: #c6c5c5;
  color: #3a3a3a;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background and text color */
}

body.dark-mode {
  background-color: #3a3a3a;
  color: #c6c5c5;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background and text color */
}

.main_context {
  display: flex;
  height: calc(100vh - 200px);
  gap: 5rem;
  align-items: center;
  justify-content: center;

}

.name {
  font-size: 5rem;
  line-height: 1.2;
}

.text {
  font-size: 18px;
}

.links_container {
  display: flex;
  justify-content: space-evenly;
  padding: 70px;
}

.links {
  font-size: 20px;
  text-decoration: none;
  color: inherit;
}

.links:hover {
  opacity: 0.8;
  color: #5e5bae;
  transition: all 0.3s ease-in;
}

.links:last-child {
  margin-bottom: 0;
}

.image {
  animation: morph 10s ease-in-out infinite;
  background-image: url(../public/self.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid #b5b5b5;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  height: 250px;
  position: relative;
  transition: all 1s ease-in-out;
  width: 15rem;
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.image:hover {
  border: 3px solid #ffffff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .name {
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 52px;
    margin-bottom: 20px;
    overflow: scroll;
  }
}


.toggle-switch {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 30px;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
}

.toggle-switch .toggle-button {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch .toggle-button::before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .toggle-button {
  background-color: #5b62ae; /* Dark mode background color */
}

.toggle-switch input:checked + .toggle-button::before {
  transform: translateX(20px); /* Move the circle to the right */
}

/* Smooth transition for toggle switch */
.toggle-switch .toggle-button {
  transition: background-color 0.3s ease;
}
